import { fullTextUpdate } from '../../mocks/index';
import {DestroyRef, inject, OnInit, output} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { OheaderFacade } from '../../services/oheader.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {IDialogStep, IsRead, IUpdate} from '../../model/header.model';
import { assetUrl } from '../../../single-spa/asset-url';
import { UpdateDialogComponent } from '../update-dialog/update-dialog.component';
import {finalize} from 'rxjs';

@Component({
  selector: 'app-updates-banner',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule, UpdateDialogComponent],
  templateUrl: './updates-banner.component.html',
  styleUrl: './updates-banner.component.scss'
})
export class UpdateBannerComponent implements OnInit{
  close = output();
  fullTextUpdate = fullTextUpdate;
  faTimes = faTimes;

  updates: IUpdate[] = [];
  visibleTexts: IUpdate[] = [];
  currentIndex: number = 0;
  showSeeMore: boolean = false;
  visible: boolean = false;
  steps: IDialogStep[] = [];

  private oheaderFacade = inject(OheaderFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.startTextRotation();
    this.oheaderFacade
      .getBannerUpdates()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IUpdate[] | null) => {
        if (res) {
          this.updates = res;
          this.visibleTexts = [this.updates[this.currentIndex]];
          this.steps = res.map((update: IUpdate) => ({
            id: update.id,
            newsId: update.newsId,
            imgPath: update.newsImage || assetUrl('default-update.png'),
            headerText: update.headline,
            textAlign: update.newsPosition === 1 ? 'LEFT' : 'RIGHT',
            content: update.newsDescription,
            date: this.formatReadableDate(update.newsFrom),
            isRead: update.isRead
          }));
        }
      });
  }

  startTextRotation(): void {
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.updates.length;
      if (this.updates[this.currentIndex]) {
        this.visibleTexts = [this.updates[this.currentIndex]];
      }
      this.updates[this.currentIndex].headline.length >= 149 ? (this.showSeeMore = true) : (this.showSeeMore = false);
    }, 6000);
  }

  handleShowUpdate(updateInfo: IUpdate) {
    this.oheaderFacade.setUpdateClickedChanged(updateInfo);
    this.visible = true;
    if (!updateInfo.isRead){
      const newsId: IsRead = {
        isRead:true
      }
      this.oheaderFacade.setCustomerNews(newsId,updateInfo?.newsId).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }
    this.oheaderFacade.setOpenCustomer().pipe(
      takeUntilDestroyed(this.destroyRef),
      finalize(() => {})
    ).subscribe(res => {
      this.oheaderFacade.setNumberOfUpdates(0);
    });
  }

  onCloseClick() {
    this.oheaderFacade.setShowUpdatesBanner(false);
  }

  handleClose(newValue: boolean) {
    this.visible = newValue;
    this.oheaderFacade.setShowUpdateOverlayPanel(false);
  }

  formatReadableDate(isoString: string): string {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };

    return date.toLocaleString('en-US', options);
  }
}
