import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AngularSvgIconModule, SvgIconRegistryService } from 'angular-svg-icon';
import { assetUrl } from '../../../single-spa/asset-url';

@NgModule({
  imports: [CommonModule, AngularSvgIconModule.forRoot()],
  exports: [CommonModule, AngularSvgIconModule],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class svgIconModule {
  constructor(private iconReg: SvgIconRegistryService) {
    // if the icon is added here we can use it just by use its name like below
    //      <svg-icon name="calendar"></svg-icon>;
    // if not
    //    <svg-icon src="calendar.svg"></svg-icon>;

    this.iconReg.loadSvg(assetUrl('icons/onfusion-logo.svg'), 'onfusionLogo');
    this.iconReg.loadSvg(assetUrl('icons/updates-icon.svg'), 'updatesIcon');
    this.iconReg.loadSvg(assetUrl('icons/notification-icon.svg'), 'notificationsIcon');
    this.iconReg.loadSvg(assetUrl('icons/oval.png'), 'ovalIcon');
    this.iconReg.loadSvg(assetUrl('icons/updates-icon-lg.svg'), 'updatesIconLg');
    this.iconReg.loadSvg(assetUrl('icons/notifications-icon-lg.svg'), 'notificationsIconLg');
    this.iconReg.loadSvg(assetUrl('menu-icon.svg'), 'menu-icon');

    // // Register other SVG icons here
  }
}
