<div class="flex align-items-center">
  <p-divider layout="vertical" />

  <div
    class="relative flex align-items-center justify-content-center border-circle cursor-pointer pt-1 pl-1 updates-container"
    pBadge
    [value]="updatesCount"
    severity="danger"
    [badgeDisabled]="!updatesCount"
    (click)="updatesPopup.toggle($event); this.isPopupOpen = !this.isPopupOpen"
    [ngClass]="{
      ' updates-container--active': this.isPopupOpen,
      ' updates-container--inactive': !this.isPopupOpen,
    }"
  >
    <svg-icon name="updatesIcon" />
  </div>

  <p-overlayPanel
    #updatesPopup
    styleClass="overlay-container"
    (onHide)="hideOverlayPanel()"
    (onShow)="showOverlayPanel()"
  >
    <app-updates-banner/>
  </p-overlayPanel>

  <div
    class="ml-3 relative flex align-items-center justify-content-center border-circle cursor-pointer pt-1 pl-1 updates-container"
    pBadge
    [value]="notificationsCount"
    severity="danger"
    [badgeDisabled]="!notificationsCount"
    (click)="notificationsPopup.toggle($event); this.isNotificationsPopupOpen = !this.isNotificationsPopupOpen"
    [ngClass]="{
      ' updates-container--active': this.isNotificationsPopupOpen,
      ' updates-container--inactive': !this.isNotificationsPopupOpen,
    }"
  >
    <svg-icon name="notificationsIcon" />
  </div>

  <p-overlayPanel
    #notificationsPopup
    styleClass="overlay-container"
    (onHide)="hideNotificationsOverlayPanel()"
    (onShow)="showNotificationsOverlayPanel()"
  >
    <app-notifications-popup (hidePopup)="notificationsPopup.toggle(false)" />
  </p-overlayPanel>

  <p-divider layout="vertical" class="divider" />

  <div (click)="op.toggle($event)" class="cursor-pointer py-3">
    <div class="flex gap-2 align-items-center">
      <div class="avatar border-circle avatar-size flex justify-content-center align-items-center">
        @if(userInfo?.profilePic!==null){
        <p-avatar styleClass="mr-2" size="large" shape="circle">
          <img [src]="imgHost + userInfo?.profilePic" alt="" />
        </p-avatar>
        }@else{
        <p-avatar styleClass="mr-2" size="large" shape="circle"><fa-icon icon="user" /></p-avatar>
        }
      </div>
      <span class="user-name font-semibold block text-sm w-4rem">
        @if(userInfo?.firstName && userInfo?.lastName){
        {{ (userInfo?.firstName || '') + ' ' + (userInfo?.lastName || '') }}
        } @else {
        <div class="mt-2">
          <p-skeleton height=".7rem" />
        </div>
        }
      </span>

      <fa-icon [icon]="faChevronDown" class="text-primary" />
    </div>
    <p-overlayPanel #op>
      <div class="flex flex-column gap-1 w-9rem">
        <div
          class="cursor-pointer profile-dropdown-items p-2 border-round-lg text-base"
          (click)="handleRouteProfile(); op.toggle($event)"
        >
          Manage Profile
        </div>
        <div
          class="cursor-pointer profile-dropdown-items p-2 border-round-lg text-base"
          (click)="handleLogout(); op.toggle($event)"
        >
          Logout
        </div>
      </div>
    </p-overlayPanel>
  </div>
</div>

<o-update-dialog
  [steps]="steps"
  [visible]="visible"
  (visibleChange)="handleClose($event)"
/>
