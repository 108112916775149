import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faStar,
  faVolumeUp,
  faBell,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { DividerModule } from 'primeng/divider';
import { NgOptimizedImage } from '@angular/common';
import {assetUrl} from '../../../single-spa/asset-url';

@Component({
  selector: 'app-personal-info',
  standalone: true,
  imports: [
    ButtonModule,
    AvatarModule,
    BadgeModule,
    FontAwesomeModule,
    DividerModule,
    NgOptimizedImage,
  ],
  templateUrl: './personal-info.component.html',
  styleUrl: './personal-info.component.scss',
})
export class PersonalInfoComponent {
  faStar = faStar;
  faVolumeUp = faVolumeUp;
  faBell = faBell;
  faChevronDown = faChevronDown;

  onDropdownClick() {}

  protected readonly assetUrl = assetUrl;
}
