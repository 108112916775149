import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AppsListComponent } from './apps-list/apps-list.component';
import { onFusionApps } from '../../mocks/onfusion-apps';

@Component({
  selector: 'app-onfusions-apps',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, AppsListComponent],
  templateUrl: './onfusions-apps.component.html',
  styleUrl: './onfusions-apps.component.scss',
})
export class OnfusionsAppsComponent {
  showDialog = signal(false);

  // Add Icon
  faBars = faBars;
  faTimes = faTimes;

  buttonHover = false;
  onFusionApps = onFusionApps;

  toggleDialog() {
    this.showDialog.set(!this.showDialog());
  }
}
