<div class="flex flex-column">
  <div class="p-3"><h3 class="text-center text-base my-0">Announcments & updates</h3></div>
  <p-divider styleClass="my-0" />
  <div class="overflow-auto h-20rem flex flex-column gap-0">
    @for (item of updates; track $index) {
    <div
      [ngClass]="{'not-read':!item.isRead}"
      class="flex gap-2 updates-container py-1 px-2 border-round-lg cursor-pointer"
      [ngStyle]="{ margin: $index === 0 ? '0.7rem 0.7rem 0.7rem 0.7rem' : '0rem 0.7rem 0.7rem 0.7rem' }"
      (click)="updateClicked(item)"
    >
      <div class="flex flex-column justify-content-start w-3rem h-3rem mt-1">
        <div
          class="flex justify-content-center align-items-center bg-tertiary-50 border-round-lg update-container h-full pt-1 pl-1"
        >
          <svg-icon name="updatesIcon" />
        </div>
      </div>
      <div class="w-18rem">
        <p class="my-0 font-semibold text-base text-color-tertiary-500">{{item.headline}}</p>
        <p class="my-0 font-medium text-sm text-color-tertiary-300">{{this.formatReadableDate(item.newsFrom)}}</p>
      </div>
    </div>
    }@empty {
    <div class="flex-1 flex flex-column justify-content-center align-items-center w-20rem empty-container">
      <svg-icon name="updatesIconLg" />
      <p class="text-color-tertiary-500 text-base">You have no updates!</p>
    </div>
    }
  </div>
</div>
