import { fullTextUpdate } from '../../mocks/index';
import { Component, output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-updates-banner',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './updates-banner.component.html',
  styleUrl: './updates-banner.component.scss',
})
export class UpdateBannerComponent {
  close = output();
  fullTextUpdate = fullTextUpdate;
  faTimes = faTimes;

  onCloseClick() {
    this.close.emit();
  }
}
