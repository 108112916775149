<div class="flex items-center bg-gray-100 rounded-full px-3 py-2 shadow-sm max-w-md searchBar border-round-2xl">
  <fa-icon [icon]="faSearch" class="text-gray-400 mr-2 cursor-pointer">
  </fa-icon>

  <input
    type="text"
    [(ngModel)]="searchQuery"
    (input)="onSearchChange()"
    placeholder="Search order name, marketplace"
    class="bg-transparent border-none outline-none text-gray-600 w-full"
  />
</div>
