import {Component, DestroyRef, EventEmitter, inject, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { svgIconModule } from '../../shared/modules';
import { OheaderFacade } from '../../services/oheader.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IsRead, IUpdate} from '../../model/header.model';

@Component({
  selector: 'app-updates-banner',
  standalone: true,
  imports: [DividerModule, svgIconModule],
  templateUrl: './updates-popup-component.html',
  styleUrl: './updates-popup.component.scss'
})
export class UpdatesPopupComponent implements OnInit {
  updates: IUpdate[] = [];

  private oheaderFacade = inject(OheaderFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.oheaderFacade
      .getBannerUpdates()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IUpdate[] | null) => {
        if (res) {
          this.updates = res;
        }
      });
  }

  formatReadableDate(isoString: string): string {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };

    return date.toLocaleString('en-US', options);
  }

  updateClicked(updateInfo: IUpdate) {
    this.oheaderFacade.setUpdateClickedChanged(updateInfo);
    updateInfo.isRead = true;
    const newsId: IsRead = {
      isRead:true
    }
    this.oheaderFacade.setCustomerNews(newsId,updateInfo?.newsId).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
