import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MicrofrontendsSharedService {

  subscribeToUserUpdates(): Observable<Event> {
    return  fromEvent(window, 'dashboardApp-user-profile-updated');
  }

  subscribeToProfilePicUpdates(): Observable<Event> {
    return  fromEvent(window, 'dashboardApp-user-profile-pic-updated');
  }
}
