<div class="app-group">
  <p class="text-lg mb-3 text-gray-400">{{ title() }}</p>
  <div class="grid">
    @for (app of apps(); track app.id) {
      <div class="flex align-items-center gap-2 col-4">
        <div class="relative bg-gray-100
                    flex align-items-center
                    justify-content-center
                    border-circle icon-size">
          <fa-icon [icon]="app.icon"></fa-icon>
        </div>
        <span>{{ app.name }}</span>
      </div>
    }
  </div>
</div>
