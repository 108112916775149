import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { IDialogStep, IsRead } from '../../model/header.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OheaderFacade } from '../../services/oheader.facade';
import { finalize } from 'rxjs';

@Component({
  selector: 'o-update-dialog',
  standalone: true,
  imports: [ButtonModule, DialogModule, TooltipModule, CommonModule],
  templateUrl: './update-dialog.component.html',
  styleUrl: './update-dialog.component.scss'
})
export class UpdateDialogComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() steps!: IDialogStep[];
  currentStep: number = 0;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  private oheaderFacade = inject(OheaderFacade);
  private destroyRef = inject(DestroyRef);

  handleBack() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  handleNext() {
    this.markAsRead();
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  handleFinish() {
    this.markAsRead();
    this.currentStep = 0;
    this.visibleChange.emit(false);
  }

  private markAsRead() {
    const updateInfo: IDialogStep = this.steps[this.currentStep];
    if (!updateInfo.isRead) {
      const newsId: IsRead = {
        isRead: true
      };
      this.oheaderFacade.setCustomerNews(newsId, updateInfo?.newsId).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }
  }

  ngOnInit(): void {
    this.oheaderFacade.getUpdateClickedChanged().pipe(
      takeUntilDestroyed(this.destroyRef),
      finalize(() => {
      })
    ).subscribe(data => {
      if (data?.id) {
        if (this.steps)
          this.currentStep = this.steps.findIndex((update: IDialogStep) => update.id === data.id);
      }
    });
  }
}
