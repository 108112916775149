import { Component } from '@angular/core';
import { PersonalInfoComponent } from '../personal-info/personal-info.component';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { LogoComponent } from '../logo/logo.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [PersonalInfoComponent, SearchBarComponent, LogoComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {}
