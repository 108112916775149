import { faCalculator } from '@fortawesome/free-solid-svg-icons';

export const onFusionApps = {
  frequentlyUsed: [
    { id: 1, name: 'OCademy', icon: faCalculator },
    { id: 2, name: 'OCreate', icon: faCalculator },
    { id: 3, name: 'OEdit', icon: faCalculator },
    { id: 4, name: 'OnBless', icon: faCalculator },
    { id: 5, name: 'OnVirtual', icon: faCalculator },
  ],
  others: [
    { id: 6, name: 'OnDesk', icon: faCalculator },
    { id: 7, name: 'OMart', icon: faCalculator },
    { id: 8, name: 'OShop', icon: faCalculator },
    { id: 9, name: 'OStaff', icon: faCalculator },
    { id: 10, name: 'OChat', icon: faCalculator },
    { id: 11, name: 'OWallet', icon: faCalculator },
    { id: 12, name: 'ODomain', icon: faCalculator },
    { id: 13, name: 'OCommerce', icon: faCalculator },
    { id: 14, name: 'OCreate', icon: faCalculator },
    { id: 15, name: 'OVirtual', icon: faCalculator },
    { id: 16, name: 'OPost', icon: faCalculator },
  ],
};
