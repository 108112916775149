import { Component, computed, DestroyRef, effect, inject, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OheaderFacade } from '../../services/oheader.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';
import { IDialogStep, IProfileInfo, IUpdate } from '../../model/header.model';
import { IApiResponseWithData } from '../../model/api.model';
import { IconsModule, svgIconModule } from '../../shared/modules';
import { UpdatesPopupComponent } from '../updates-popup/updates-popup.component';
import { Router } from '@angular/router';
import { BASE_ROUTE_URL_DASHBOARD } from '../../shared/constants/global.constant';
import { SkeletonModule } from 'primeng/skeleton';
import { environment } from '../../../environments/environment';
import { assetUrl } from '../../../single-spa/asset-url';
import { UpdateDialogComponent } from '../update-dialog/update-dialog.component';
import { NotificationsPopupComponent } from '../notifications-popup/notifications-popup.component';
import { NotificationsService } from '../../services/notifications.service';
import { MicrofrontendsSharedService } from '../../services/microfrontends-shared.service';

@Component({
  selector: 'app-personal-info',
  standalone: true,
  imports: [
    ButtonModule,
    AvatarModule,
    BadgeModule,
    FontAwesomeModule,
    DividerModule,
    OverlayPanelModule,
    svgIconModule,
    UpdatesPopupComponent,
    IconsModule,
    SkeletonModule,
    UpdateDialogComponent,
    NotificationsPopupComponent
  ],
  templateUrl: './personal-info.component.html',
  styleUrl: './personal-info.component.scss'
})
export class PersonalInfoComponent implements OnInit {
  protected readonly assetUrl = assetUrl;
  private oheaderFacade = inject(OheaderFacade);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private notificationsService = inject(NotificationsService);
  private microfrontendsSharedService = inject(MicrofrontendsSharedService);

  faChevronDown = faChevronDown;
  updatesCount: number = 0;
  notificationsCount: number = 0;
  userInfo!: IProfileInfo | null;
  isPopupOpen: boolean = false;
  isNotificationsPopupOpen: boolean = false;
  imgHost = environment.imgHostUrl;
  steps: IDialogStep[] = [
    {
      id: 0,
      imgPath: '',
      headerText: '',
      textAlign: '',
      content: '',
      date: '',
      newsId: 0,
      isRead: false
    }
  ];
  visible: boolean = false;
  currentStep: number = 0;
  unreadNotificationsCount = computed(() => this.notificationsService.getUnreadNotificationsCount()());


  constructor() {
    effect(() => {
      this.notificationsCount = this.unreadNotificationsCount() ?? 0;
    });
  }


  ngOnInit(): void {
    this.getBannerUpdates();
    this.fetchProfileInfo();

    this.oheaderFacade.getUpdateClickedChanged().pipe(
      takeUntilDestroyed(this.destroyRef),
      finalize(() => {
      })
    ).subscribe(data => {
      if (this.steps && data) {
        this.currentStep = this.steps.findIndex((update: IDialogStep) => update.id === data.id);
        this.visible = true;
      }
    });

    this.oheaderFacade.getNumberOfUpdates().pipe(
      takeUntilDestroyed(this.destroyRef),
      finalize(() => {
      })
    ).subscribe(numbers => {
      this.updatesCount = numbers;
    });

    this.subscribeToUserUpdates();
    this.subscribeToProfilePicUpdates();
  }

  getBannerUpdates() {
    this.oheaderFacade
      .getBannerUpdates()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
        })
      )
      .subscribe((res: IUpdate[] | null) => {
        if (res) {
          this.steps = res.map((update: IUpdate) => ({
            id: update.id,
            newsId: update.newsId,
            imgPath: update.newsImage || assetUrl('default-update.png'),
            headerText: update.headline,
            isRead: update.isRead,
            isOpen: update.isOpen,
            textAlign: update.newsPosition === 1 ? 'LEFT' : 'RIGHT',
            content: update.newsDescription,
            date: this.formatReadableDate(update.newsFrom)
          }));
        }
      });
  }

  fetchProfileInfo() {
    this.oheaderFacade
      .fetchProfileInfo()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IApiResponseWithData<IProfileInfo>) => {
        if (res) {
          this.userInfo = res.data;
        }
      });
  }

  handleRouteProfile() {
    this.router.navigate([BASE_ROUTE_URL_DASHBOARD]).then((res) => {
      this.router.navigate([BASE_ROUTE_URL_DASHBOARD + 'myProfile']);
    });
  }

  handleLogout() {
    this.oheaderFacade
      .logout()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        window.location.replace(environment.frontendUrlSSO);
      });
  }

  handleClose(newValue: boolean) {
    this.visible = newValue;
  }

  formatReadableDate(isoString: string): string {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',

      hour12: false
    };

    return date.toLocaleString('en-US', options);
  }

  hideOverlayPanel() {
    this.isPopupOpen = false;
    this.oheaderFacade.setShowUpdateOverlayPanel(false);
  }

  showOverlayPanel() {
    this.isPopupOpen = true;
    this.oheaderFacade.setShowUpdateOverlayPanel(true);
    this.oheaderFacade.setOpenCustomer().pipe(
      takeUntilDestroyed(this.destroyRef),
      finalize(() => {
      })
    ).subscribe(res => {
      this.updatesCount = 0;
    });
  }

  hideNotificationsOverlayPanel() {
    this.isNotificationsPopupOpen = false;
  }

  showNotificationsOverlayPanel() {
    this.isNotificationsPopupOpen = true;
  }

  private subscribeToUserUpdates(): void {
    this.microfrontendsSharedService.subscribeToUserUpdates()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: Event) => {
        const customEvent = event as CustomEvent;
        if (this.userInfo) {
          this.userInfo.firstName = customEvent.detail.firstName;
          this.userInfo.lastName = customEvent.detail.lastName;
        }
      });
  }

  private subscribeToProfilePicUpdates(): void {
    this.microfrontendsSharedService.subscribeToProfilePicUpdates()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: Event) => {
        console.log(event);
        const customEvent = event as CustomEvent;
        if (this.userInfo) {
          this.userInfo.profilePic = customEvent.detail.profilePic;
        }
      });
  }

  protected readonly environment = environment;
  protected readonly length = length;
}
