// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.p-2-5 {
  padding: 0.625rem;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAAA,8EAAA;AACA;;EAEE,SAAA;EACA,UAAA;EACA,sBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":["/* You can add global styles to this file, and also import other style files */\nhtml,\nbody {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.p-2-5 {\n  padding: 0.625rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
