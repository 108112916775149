import { Injectable, signal } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { RxStompConfig } from '@stomp/rx-stomp';
import SockJS from 'sockjs-client';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private rxStomp = new RxStomp();
  notificationSignal = new BehaviorSubject<Notification | null>(null);
  allNotificationsSignal = new BehaviorSubject<Notification[] | null>(null);
  unreadNotificationsCount = signal<number | null>(null);

  constructor() {
    this.initializeWebSocketConnection();
  }

  private initializeWebSocketConnection(): void {
    const rxStompConfig: RxStompConfig = {
      webSocketFactory: () => new SockJS(environment.socketUrl),
    };

    this.rxStomp.configure(rxStompConfig);
    this.rxStomp.activate();

    this.rxStomp.serverHeaders$.subscribe((frame) => {
      const customerAccountId = frame['user-name'];
      this.sendNotificationToAll(customerAccountId);
      this.sendNotificationCountRequest(customerAccountId);
      this.subscribeToNotifications();
      this.getQueueNotifications(customerAccountId);
    });
  }

  private sendNotificationToAll(customerAccountId: string): void {
    this.rxStomp.publish({
      destination: '/notification-all',
    });

    this.rxStomp.watch(`/user/${customerAccountId}/queue/notification-all`).subscribe({
      next: (message) => {
        this.saveAllNotificationsMessage(message.body);
      },
      error: (err) => {
        console.error("Error while get topic notifications:", err);
      },
    });
  }

  private sendNotificationCountRequest(customerAccountId: string): void {
    this.rxStomp.publish({ destination: '/notification-count'});

    this.rxStomp.watch(`/user/${customerAccountId}/queue/notification-count`).subscribe({
      next: (message) => {
        this.unreadNotificationsCount.set(Number(message.body));
      },
      error: (err) => {
        console.error("Error while get topic notifications:", err);
      },
    });
  }

  sendNotificationRead(notificationId: string): void {
    const payload = {
      notificationId: notificationId,
      isRead: 1,
    };

    this.rxStomp.publish({
      destination: '/notification-read',
      body: JSON.stringify(payload),
    });
  }

  private subscribeToNotifications(): void {
    this.rxStomp.watch('/topic/notification').subscribe({
      next: (message) => {
        this.saveNotificationMessage(message.body);
      },
      error: (err) => {
        console.error("Error while get topic notifications:", err);
      },
    });
  }

  private getQueueNotifications(customerAccountId: string): void {
    this.rxStomp.watch(`/user/${customerAccountId}/queue/notification`).subscribe({
      next: (message) => {
        this.saveNotificationMessage(message.body);
      },
      error: (err) => {
        console.error("Error while get queue notifications:", err);
      },
    });
  }

  private saveAllNotificationsMessage(message: string): void {
    try {
      const parsedMessage = JSON.parse(message);
      this.allNotificationsSignal.next(parsedMessage);
    } catch (error) {
      console.error('Error while processing all notifications message:', error);
    }
  }

  private saveNotificationMessage(message: string): void {
    try {
      const parsedMessage = JSON.parse(message);
      this.notificationSignal.next(parsedMessage);
    } catch (error) {
      console.error('Error while processing notification message:', error);
    }
  }

  getUnreadNotificationsCount() {
    return this.unreadNotificationsCount;
  }


  disconnectSocket(): void {
    this.rxStomp.deactivate();
  }
}
