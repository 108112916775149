import { Component } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { UpdateBannerComponent } from './components/updates-banner/updates-header.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [HeaderComponent, UpdateBannerComponent, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    class: 'container',
  },
})
export class AppComponent {
  title = 'o-suite-header';
  showUpdatesBanner = true;

  onUpdatesBannerClose() {
    this.showUpdatesBanner = false;
  }
}
