<div class="menu-container">

<!--  ToDo: Change Menu Button to be a separate component-->
  <!-- Menu Button -->
  <div
    (click)="toggleDialog()"
    class="menu-button relative
      flex align-items-center
      justify-content-center
      border-circle icon-size
      cursor-pointer border-1"
    [ngClass]="{
      'border-primary': showDialog(),
      'border-gray-300': !showDialog()
    }">
    <fa-icon
      [icon]="showDialog() ? faTimes : faBars"
      [ngClass]="{ 'menu-icon--active': showDialog() }"
      class="menu-icon text-red"
    ></fa-icon>
  </div>

  <!-- Dialog -->
  @if (showDialog()) {
    <div class="dialog p-4 absolute bg-white shadow-lg border-round-lg shadow-2 z-5">
      <h3 class="m-0">OnFusion apps</h3>

      <app-apps-list
        [apps]="onFusionApps.frequentlyUsed"
        title="frequently used"
      />

      <app-apps-list [apps]="onFusionApps.others" title="others"></app-apps-list>

      <div class="footer mt-4 flex justify-content-end">
        <button
          class="explore-button bg-transparent border-1 border-primary text-primary py-2 px-3 border-round-2xl cursor-pointer font-bold"
          (mouseover)="buttonHover = true"
          (mouseout)="buttonHover = false"
          [ngClass]="{ 'bg-primary text-white': buttonHover }">
          Explore more in the Marketplace
        </button>
      </div>
    </div>
  }
</div>
