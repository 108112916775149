import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IApiResponseWithData } from '../model/api.model';
import { environment } from '../../environments/environment';
import { INewsData, IProfileInfo, IsRead, Notification } from '../model/header.model';

@Injectable({
  providedIn: 'root'
})
export class OheaderApi {
  osuite_ApiUrl = environment.apiUrl;
  sso_ApiUrl = environment.apiUrlSSO;

  private httpClient = inject(HttpClient);

  fetchBannerUpdates(): Observable<IApiResponseWithData<INewsData>> {
    const url = `${this.osuite_ApiUrl}customer-news`;
    return this.httpClient.get<IApiResponseWithData<INewsData>>(url);
  }

  fetchProfileInfo(): Observable<IApiResponseWithData<IProfileInfo>> {
    const url = `${this.osuite_ApiUrl}api/v2/users/profile`;

    return this.httpClient.get<IApiResponseWithData<IProfileInfo>>(url);
  }

  logout(): Observable<IApiResponseWithData<undefined>> {
    const url = `${this.sso_ApiUrl}api/user/logout`;
    return this.httpClient.post<IApiResponseWithData<undefined>>(url, {});
  }

  updateCustomerNews(body:IsRead,newsId:number): Observable<any> {
    const url = `${this.osuite_ApiUrl}customer-news/${newsId}`;
    return this.httpClient.patch<any>(url, body);
  }

  updateOpenCustomer(): Observable<any> {
    const url = `${this.osuite_ApiUrl}customer-news/open`;
    return this.httpClient.put<any>(url, {});
  }

  fetchAllNotifications(): Observable<IApiResponseWithData<Notification[]>> {
    const url = `${this.osuite_ApiUrl}api/v2/notifications/`;
    const params = new HttpParams().set('category', 'all');

    return this.httpClient.get<IApiResponseWithData<Notification[]>>(url, { params });
  }

  markNotificationAsRead(notificationId: string, isRead: number): Observable<any> {
    const url = `${this.osuite_ApiUrl}notifications/update/${notificationId}`;
    return this.httpClient.put<any>(url, { }, {params: { isRead: isRead }});
  }
}
