import { inject, Injectable } from '@angular/core';
import { OheaderState } from './oheader.state';
import { OheaderApi } from './oheaderApi';
import { IsRead, IUpdate, Notification } from '../model/header.model';
import { Observable } from 'rxjs';
import { IApiResponseWithData } from '../model/api.model';

@Injectable({
  providedIn: 'root'
})
export class OheaderFacade {
  private oheaderState = inject(OheaderState);
  private oheaderApi = inject(OheaderApi);

  setShowUpdatesBanner(state: boolean) {
    this.oheaderState.setShowUpdatesBanner(state);
  }

  getShowUpdatesBanner() {
    return this.oheaderState.getShowUpdatesBanner();
  }

  setNumberOfUpdates(num: number) {
    this.oheaderState.setNumberOfUpdates(num);
  }

  getNumberOfUpdates() {
    return this.oheaderState.getNumberOfUpdates();
  }

  setUpdateClickedChanged(obj: any) {
    this.oheaderState.setUpdateClickedChanged(obj);
  }

  getUpdateClickedChanged() {
    return this.oheaderState.getUpdateClickedChanged();
  }

  setShowUpdateOverlayPanel(state: boolean) {
    this.oheaderState.setShowUpdateOverlayPanel(state);
  }

  getShowUpdateOverlayPanel() {
    return this.oheaderState.getShowUpdateOverlayPanel();
  }

  fetchBannerUpdates() {
    return this.oheaderApi.fetchBannerUpdates();
  }

  setBannerUpdates(bannerUpdated: IUpdate[]) {
    this.oheaderState.setBannerUpdates(bannerUpdated);
  }

  getBannerUpdates() {
    return this.oheaderState.getBannerUpdates();
  }

  fetchProfileInfo() {
    return this.oheaderApi.fetchProfileInfo();
  }

  logout() {
    return this.oheaderApi.logout();
  }

  setCustomerNews(body:IsRead,newsId:number) {
    return this.oheaderApi.updateCustomerNews(body,newsId);
  }

  setOpenCustomer() {
    return this.oheaderApi.updateOpenCustomer();
  }
}
