import { Component, input } from '@angular/core';
import { AppsItem } from '../../../app.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-apps-list',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule],
  templateUrl: './apps-list.component.html',
  styleUrl: './apps-list.component.scss',
})
export class AppsListComponent {
  apps = input<AppsItem[]>();
  title = input<string>();
}
