export const environment = {
  publicPath: 'https://o-suite-header-dev.onfusiondemo.cc',
  apiUrl: 'https://oes-api-dev.onfusiondemo.cc/oesuser/',
  apiUrlSSO: 'https://oes-api-dev.onfusiondemo.cc/sso/',
  frontendUrlSSO: 'https://o-suite-sso-dev.onfusiondemo.cc',
  production: false,
  imgHostUrl: 'https://d2bsezkvf0wpv4.cloudfront.net/',
  redirectUrlIfUserNotLoggedIn: 'https://o-suite-sso-dev.onfusiondemo.cc/login',
  socketUrl: "https://oes-api-dev.onfusiondemo.cc/oesuser/ws"
};
