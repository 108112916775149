import {
  Component,
  EventEmitter,
  inject, OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { svgIconModule } from '../../shared/modules';
import { Notification } from '../../model/header.model';
import { NotificationsService } from '../../services/notifications.service';
import { Router, RouterLink } from '@angular/router';
import {BASE_ROUTE_URL_DASHBOARD} from '../../shared/constants/global.constant';

@Component({
  selector: 'app-notifications-popup',
  standalone: true,
  imports: [DividerModule, svgIconModule, RouterLink],
  templateUrl: './notifications-popup.component.html',
  styleUrl: './notifications-popup.component.scss',
})
export class NotificationsPopupComponent implements OnInit, OnDestroy {
  private notificationsService = inject(NotificationsService);
  private router = inject(Router);

  notifications: Notification[] = [];

  @Output() hidePopup: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.notificationsService.allNotificationsSignal.subscribe((value: any) => {
      this.notifications = value || [];
    });

    this.notificationsService.notificationSignal.subscribe((value: any) => {
      if(value && this.notifications?.length > 0) this.notifications.unshift(value);
      else this.notifications.push(value);
    })
  }

  notificationClicked(item: Notification, itemIndex: number) {
    if(!item) return;

    if(!item?.isRead){
      this.notifications[itemIndex].isRead = 1;
      this.notificationsService.sendNotificationRead(item.notificationId);
    }
    this.hidePopup.emit()
    if(item.path){
      this.router.navigate([BASE_ROUTE_URL_DASHBOARD]).then((res) => {
        this.router.navigateByUrl(item.path);
      })
    }
  }

  ngOnDestroy() {
    this.notificationsService.disconnectSocket();
  }
}
