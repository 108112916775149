<div
  class="updates-banner flex align-items-center justify-content-between p-2 border-round border-1 surface-border"
>
  <span class="icon text-sm font-bold flex align-items-center gap-1">
    <span class="icon flipped-icon">📣</span> Updates{{ ": " }}
  </span>
  <div class="scrolling-container flex-grow-1 overflow-hidden">
    <div class="scrolling-text">
      {{ fullTextUpdate }}
    </div>
  </div>
  <div
    class="close-btn relative text-xs flex align-items-center justify-content-center border-circle cursor-pointer border-1 border-gray-600 text-gray-600"
    (click)="onCloseClick()"
  >
    <fa-icon [icon]="faTimes"></fa-icon>
  </div>
</div>
