import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UpdateBannerComponent } from './components/updates-banner/updates-header.component';
import { RouterOutlet } from '@angular/router';
import { OheaderFacade } from './services/oheader.facade';
import { finalize } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToasterComponent, ToasterService } from 'o-suite-lib';
import { AppInitializationService } from './services/app-Initialization.service';
import {INewsData} from './model/header.model';
import {IApiResponseWithData} from './model/api.model';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [UpdateBannerComponent, RouterOutlet, ToasterComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    class: 'container'
  }
})
export class AppComponent implements OnInit {
  title = 'o-suite-header';
  showUpdatesBanner!: boolean;
  contentLength: number = 0;

  private oheaderFacade = inject(OheaderFacade);
  private destroyRef = inject(DestroyRef);
  appInitializationService = inject(AppInitializationService);

  public toasterService = inject(ToasterService);

  closeToast() {
    this.toasterService.hide();
  }

  ngOnInit(): void {
    this.oheaderFacade
      .getShowUpdatesBanner()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {})
      )
      .subscribe((res: boolean) => {
        this.showUpdatesBanner = res;
      });

    this.fetchBannerUpdates();

    this.oheaderFacade
      .getShowUpdateOverlayPanel()
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
          this.fetchBannerUpdates();
      });
  }

  fetchBannerUpdates() {
    this.oheaderFacade
      .fetchBannerUpdates()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {})
      )
      .subscribe((res: IApiResponseWithData<INewsData>) => {
        const data = res?.data?.news;
        const unOpenedCount = res?.data?.unopenedCount;
        if (data) {
          this.oheaderFacade.setBannerUpdates(data);
          this.oheaderFacade.setNumberOfUpdates(unOpenedCount);
          this.contentLength = data.length;
        }
      });
  }
}
