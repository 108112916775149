import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IUpdate, Notification } from '../model/header.model';

@Injectable({
  providedIn: 'root'
})
export class OheaderState {
  showUpdatesBanner = new BehaviorSubject<boolean>(true);
  showUpdateOverlayPanel = new BehaviorSubject<boolean>(false);
  bannerUpdates = new BehaviorSubject<IUpdate[] | null>(null);
  numberOfUpdates = new BehaviorSubject<number>(0);
  updateClickedChanged = new BehaviorSubject<any>(null);

  setUpdateClickedChanged(obj:any) {
    this.updateClickedChanged.next(obj);
  }
  getUpdateClickedChanged() {
    return this.updateClickedChanged.asObservable();
  }

  setShowUpdatesBanner(state: boolean) {
    this.showUpdatesBanner.next(state);
  }
  getShowUpdatesBanner() {
    return this.showUpdatesBanner.asObservable();
  }

  setNumberOfUpdates(num: number) {
    this.numberOfUpdates.next(num);
  }
  getNumberOfUpdates() {
    return this.numberOfUpdates.asObservable();
  }

  setShowUpdateOverlayPanel(state: boolean) {
    this.showUpdateOverlayPanel.next(state);
  }

  getShowUpdateOverlayPanel() {
    return this.showUpdateOverlayPanel.asObservable();
  }

  setBannerUpdates(bannerUpdates: IUpdate[]) {
    this.bannerUpdates.next(bannerUpdates);
  }
  getBannerUpdates() {
    return this.bannerUpdates.asObservable();
  }
}
