<div class="flex align-items-center">
  <div class="px-2 flex align-items-center">
    <div class="credit-badge flex align-items-center mr-3 bg-green-50 p-2-5 border-round-2xl">
      <fa-icon [icon]="faStar" class="mr-2" style="color: #f39c12"></fa-icon>
      <span class="text-green-600 font-bold">10.00
        <span class="text-green-600 text-xs font-normal"> Credit</span>
      </span>
    </div>

    <p-divider layout="vertical"/>
    <div class="notifications flex align-items-center gap-4">
      <div class="relative bg-gray-100 flex align-items-center
               justify-content-center border-circle icon-size
               cursor-pointer">
        <fa-icon [icon]="faVolumeUp"></fa-icon>

        <p-badge
          [value]="2"
          class="absolute top-0 right-0 text-white"
          [style.transform]="'translate(50%, -50%)'"
          severity="danger"
        />
      </div>
      <div class="relative bg-gray-100
           flex align-items-center justify-content-center
           border-circle icon-size cursor-pointer">
        <fa-icon [icon]="faBell"></fa-icon>
        <p-badge
          [value]="2"
          class="absolute top-0 right-0 text-white"
          [style.transform]="'translate(50%, -50%)'"
          severity="danger"
        />
      </div>
    </div>
  </div>
  <p-divider layout="vertical" [class]="'px-2 divider'"/>

  <div class="user-profile flex align-items-center cursor-pointer">
    <div class="avatar border-circle
                avatar-size
                flex justify-content-center
                align-items-center bg-gray-100 mr-2">
      <img [ngSrc]="assetUrl('oval.png')" alt="avatar" height="40" width="40"/>
    </div>
    <div class="user-details mr-2">
      <span
        class="user-name font-semibold block text-sm"
        style="margin-bottom: -8px">
        Glenn Walters
      </span>
      <span class="user-id text-600 text-xs">OES-311038</span>
    </div>
    <button
      (click)="onDropdownClick()"
      class="p-button-plain bg-transparent border-none">
      <fa-icon [icon]="faChevronDown" class="text-primary"></fa-icon>
    </button>
  </div>
</div>
